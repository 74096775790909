import { DeleteOutlined, EditOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin, Steps } from "antd";
import React, { useState, useEffect, useContext } from "react";

import EventLogItemModal from "./logItem/EventLogItemModal";
import { Context } from "../../../..";
import actionLogService from "../../../../services/actionLogService";
import notificationService from "../../../../services/notificationService";
import templateLogsService from "../../../../services/templateLogsService";
import { notificationWarehouse } from "../../../../utils/helpers";
import { requestWrapper } from "../../../../utils/requestWrapper";

const EventLogModal = ({ active, setActive, timetable, template }) => {
  const { user } = useContext(Context);
  const [eventLogItems, setEventLogItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isEventLogItemModalVisible, setIsEventLogItemModalVisible] = useState(false);
  const [eventLogItemType, setEventLogItemType] = useState("");
  const [selectedEventLogItem, setSelectedEventLogItem] = useState(null);

  const entity = timetable || template;
  const entityType = timetable ? "timetable" : "template";

  const [getEventLogsTimetable] = requestWrapper({
    requestFunction: notificationService.getEventLogsTimetable,
    onSuccess: (data) => {
      if (data?.length) {
        setEventLogItems(data);
      } else {
        setEventLogItems([]);
      }
    },
    onError: (message) => {
      notificationWarehouse("error", message);
    },
    setLoading: setLoading,
  });

  const [getEventLogsTemplate] = requestWrapper({
    requestFunction: templateLogsService.getEventLogsTemplate,
    onSuccess: (data) => {
      if (data?.length) {
        setEventLogItems(data);
      } else {
        setEventLogItems([]);
      }
    },
    onError: (message) => {
      notificationWarehouse("error", message);
    },
    setLoading: setLoading,
  });

  //todo: revert deleted row ONE
  const [restoreEventLogItem] = requestWrapper({
    requestFunction: actionLogService.restoreEventLog,
    onSuccess: () => {
      setLoading(true);
      getEventLogsTimetable(entity.id);
    },
    onError: (message) => {
      notificationWarehouse("error", message);
    },
    setLoading: setLoading,
  });

  const handleCloseEventLogItemModal = (refreshData) => {
    setIsEventLogItemModalVisible(false);
    if (refreshData) {
      setLoading(true);
      setTimeout(() => {
        if (entityType === "timetable") {
          getEventLogsTimetable(entity.id);
        } else {
          getEventLogsTemplate(entity.id);
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (active && entity) {
      setLoading(true);
      if (entityType === "timetable") {
        getEventLogsTimetable(entity.id);
      } else {
        getEventLogsTemplate(entity);
      }
    }
  }, [active, entity]);

  return (
    <Modal
      title="История события"
      open={active}
      onCancel={() => {
        setActive(false);
      }}
      footer={
        user.user.type_id === 5 && entityType === "timetable" ? (
          <Row justify="end">
            <Button
              type="primary"
              onClick={() => {
                setIsEventLogItemModalVisible(true);
                setEventLogItemType("create");
              }}
            >
              Добавить новое событие
            </Button>
          </Row>
        ) : (
          <></>
        )
      }
    >
      <Spin spinning={isLoading}>
        <Steps progressDot direction="vertical" current={eventLogItems.length}>
          {eventLogItems.map((item) => (
            <Steps.Step
              key={item.id}
              title={item.title}
              subTitle={item.subTitle}
              description={
                <Row justify="space-between" align="middle">
                  <Col>{item.description}</Col>
                  {!item.isDeleted && user.user.type_id === 5 && entityType === "timetable" ? (
                    <Col>
                      <Button
                        icon={<EditOutlined />}
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          setIsEventLogItemModalVisible(true);
                          setEventLogItemType("edit");
                          setSelectedEventLogItem(item);
                        }}
                      />
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          setIsEventLogItemModalVisible(true);
                          setEventLogItemType("delete");
                          setSelectedEventLogItem(item);
                        }}
                      />
                    </Col>
                  ) : (
                    item.isDeleted &&
                    user.user.type_id === 5 &&
                    entityType === "timetable" && (
                      <Col>
                        <Button
                          icon={<RedoOutlined />}
                          style={{ marginRight: "5px" }}
                          onClick={() => restoreEventLogItem({ eventLogId: item.id })}
                        />
                      </Col>
                    )
                  )}
                </Row>
              }
              status={item.isDeleted ? "wait" : "process"}
              icon={item.isDeleted ? { style: { color: "#bfbfbf" } } : undefined}
            />
          ))}
        </Steps>
        {isEventLogItemModalVisible && (
          <EventLogItemModal
            active={isEventLogItemModalVisible}
            setActive={handleCloseEventLogItemModal}
            eventType={eventLogItemType}
            selectedEventLogItem={selectedEventLogItem}
            setSelectedItem={setSelectedEventLogItem}
            timetable={timetable}
          />
        )}
      </Spin>
    </Modal>
  );
};

export default EventLogModal;
