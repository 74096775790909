import { Comment } from "@ant-design/compatible";
import { Button, Col, Empty, Flex, List, Row, Spin, Switch } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import "./index.css";
const NotificationDrawerContent = observer(
  ({ notifications, onDelete, onDeleteAll, onClickNotification, user, onUpdateNotificationSetting, loading }) => (
    <Flex vertical style={{ height: "100%" }}>
      <div style={{ flex: 7, overflowY: "auto" }}>
        <Col className="notification-wrapper">
          {notifications.length !== 0 ? (
            <Spin spinning={loading}>
              <div className="notification-wrapper-children">
                <List
                  key={`notification-list-${notifications.length}`}
                  dataSource={notifications}
                  itemLayout="horizontal"
                  renderItem={(item) => (
                    <Comment
                      key={`notification_${item.id}_${item.time}`}
                      className="list-body-notification"
                      content={item.notificationText}
                      datetime={dayjs(item.time).format("LLL")}
                      author={`${item.userF} ${item.userI} ${item.userO}`}
                      actions={[
                        <div key={`notification-${item.id}`} className="notification-actions">
                          <Button
                            type="primary"
                            key={`action_${item.id}_view`}
                            className="notification-action-button"
                            size="small"
                            onClick={() => onClickNotification(item)}
                          >
                            Посмотреть
                          </Button>
                          <Button
                            key={`action_${item.id}_delete`}
                            danger
                            type="primary"
                            className="notification-action-button"
                            size="small"
                            onClick={() => onDelete(item)}
                          >
                            Удалить
                          </Button>
                        </div>,
                      ]}
                    />
                  )}
                />
              </div>
            </Spin>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет новых уведомлений" />
          )}
        </Col>
      </div>

      <Flex justify="end" align="center" style={{ flex: 1, padding: "0 16px" }}>
        {notifications.length > 0 && (
          <Button danger type="primary" onClick={onDeleteAll}>
            Удалить все
          </Button>
        )}
      </Flex>

      <div style={{ flex: 2, borderTop: "1px solid #f0f0f0", paddingTop: 16 }}>
        <h3>Настройки</h3>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Switch
              checked={user.user.notificationSetting.warehouse_push}
              onChange={(checked) => {
                onUpdateNotificationSetting({ warehouse_push: checked });
                user.user.notificationSetting.warehouse_push = checked;
              }}
            />
            <span> Пуш-уведомления</span>
          </Col>
          <Col span={24}>
            <Switch
              checked={user.user.notificationSetting.warehouse_email}
              onChange={(checked) => {
                onUpdateNotificationSetting({ warehouse_email: checked });
                user.user.notificationSetting.warehouse_email = checked;
              }}
            />
            <span> Email-уведомления</span>
          </Col>
        </Row>
      </div>
    </Flex>
  )
);

export default NotificationDrawerContent;
