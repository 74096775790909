import { $host } from "../http";

class TemplateService {
  useTemplateWorkWeekNumber = ({ onSuccessful, onError }) => ({
    getWorkWeekNumber: (data) => {
      $host
        .post("/api/template/week_number", data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw response;
          }
        })
        .then((weekNumber) => {
          onSuccessful(weekNumber);
        })
        .catch((err) => {
          onError(err.message);
        });
    },
  });

  wrapperTemplateList({ onSuccessful, onError }) {
    const getData = async (sklad_use, week, start, end) => {
      $host
        .get(`/api/template/${start}/${end}/${week}/${sklad_use}`)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            throw response.data;
          }
        })
        .then((templateList) => {
          onSuccessful(templateList);
        })
        .catch((err) => {
          onError(err.message);
        });
    };

    return {
      update: (sklad_use, week, start, end) => {
        getData(sklad_use, week, start, end);
      },
    };
  }

  wrapperTemplateDelete({ onSuccessful, onError }) {
    return {
      deleteTemplate: (id) =>
        $host
          .delete(`/api/template/delete/${id}`)
          .then((res) => {
            if (res.status === 200) {
              onSuccessful();
            } else {
              throw res.data;
            }
          })
          .catch((err) => {
            onError(err.message);
          }),
    };
  }

  wrapperTemplateCreate({ onSuccessful, onError }) {
    return {
      createTemplate: (data) => {
        $host
          .post("/api/template/create", data)
          .then((res) => {
            if (res.status === 200) {
              onSuccessful();
            } else {
              throw res.data;
            }
          })
          .catch((err) => {
            console.log(err);
            onError(err.response?.data?.message);
          });
      },
    };
  }

  async checkEventDuration(data) {
    return $host.post("/api/template/check-event-duration", data);
  }

  async edit(data) {
    return $host.patch("/api/template", data);
  }

  async editShoppingCart({ id, shoppingCart }) {
    return $host.patch("/api/template/shopping-cart", { templateId: id, shoppingCart });
  }

  //todo: get list template info about one record template
  async moreInfoTemplate(templateId) {
    return $host.get(`/api/storage_template/info/${templateId}`);
  }

  //todo: get list template

  async getActionHistory(data) {
    return $host.post("/api/storage_template/action-history", data);
  }
}

export default new TemplateService();
