import { makeAutoObservable, runInAction } from "mobx";

export default class NotificationStore {
  notifications = [];
  handleClickNotification = null;
  listenerNotification = null;

  constructor() {
    makeAutoObservable(this);
    if (process.env.NODE_ENV === "development") {
      this.checkForDuplicates();
    }
  }

  checkForDuplicates() {
    const ids = this.notifications.map((n) => n.id);

    if (new Set(ids).size !== ids.length) {
      console.log("Duplicate notification IDs detected!", this.notifications);
    }
  }

  getNotification() {
    return this.notifications;
  }

  setNotification(notify = []) {
    this.notifications = notify;
  }

  addNotification(notify = []) {
    this.notifications = [...notify, ...this.notifications];
  }

  removeNotification(id) {
    runInAction(() => {
      this.notifications = this.notifications.filter((notify) => notify.id !== id);
    });
  }

  runListenerNotification = (notification) => {
    if (this.listenerNotification) {
      this.listenerNotification(notification);
    }
  };
}
