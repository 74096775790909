import dayjs from "dayjs";
import { $host } from "../http";

class UploadService {
  wrapperPdfToView({ onError }) {
    return {
      pdfView: (file_id, isDocumentPinned = false) => {
        $host
          .get(`/api/upload${isDocumentPinned ? `/document/${file_id}/${false}` : `/${file_id}`}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status === 200) {
              const blob = response.data;
              const downloadURL = window.URL.createObjectURL(blob);

              window.open(downloadURL);
            }
          })
          .catch((err) => {
            console.log(err);
            onError(err.response?.data?.message);
          });
      },
    };
  }

  wrapperImage({ onError, onSuccessDeleteFile }) {
    return {
      getImage: async (fileId, { isDocumentPinned = false }) =>
        $host
          .get(`/api/upload${isDocumentPinned ? `/document/${fileId}/${false}` : `/${fileId}`}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status === 200) {
              const imageBlob = response.data;

              return URL.createObjectURL(imageBlob);
            }
          })
          .catch((error) => {
            onError(error?.response?.data?.message);
          }),

      downloadFile: (file_id, file_name, isDocumentPinned = false, isAll = false) => {
        $host
          .get(`/api/upload${isDocumentPinned ? `/document/${file_id}/${isAll}` : `/${file_id}`}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status === 200) {
              const blob = response.data;
              const downloadURL = window.URL.createObjectURL(blob);
              const link = document.createElement("a");

              link.href = downloadURL;
              link.download = file_name;
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
          })
          .catch((err) => {
            onError(err?.response?.data?.message);
          });
      },

      uploadFileFunction: (data, date, id, isDocumentPinned = false) => {
        try {
          const dateFormat = dayjs(date).format("YYYY-MM-DD");
          const [year, month, day] = dateFormat.split("-");

          Object.values(data).forEach((files) => {
            files.forEach((el, index) => {
              if (el !== null && el?.data !== null) {
                const formData = new FormData();

                formData.append("file", el?.data);
                formData.append("type", el?.type);
                setTimeout(() => {
                  $host
                    .post(`/api/upload${isDocumentPinned ? "/document" : ""}/${year}/${month}/${day}/${id}`, formData)
                    .catch((err) => {
                      onError(err?.response?.data?.message);
                    });
                }, 200 * index);
              }
            });
          });
        } catch (error) {
          console.log(error);
          onError(error);
        }
      },

      uploadOneFileFunction: (data, date, id, isDocumentPinned = false) => {
        try {
          const dateFormat = dayjs(date).format("YYYY-MM-DD");
          const [year, month, day] = dateFormat.split("-");

          Object.values(data).forEach((el) => {
            if (el !== null && el?.data !== null) {
              const formData = new FormData();

              formData.append("file", el?.data);
              formData.append("type", el?.type);

              $host
                .post(`/api/upload${isDocumentPinned ? "/document" : ""}/${year}/${month}/${day}/${id}`, formData)
                .catch((err) => {
                  onError(err?.response?.data?.message);
                });
            }
          });
        } catch (error) {
          console.log(error);
          onError(error?.response?.data?.message);
        }
      },

      deleteFile: (id, { isMarkDelete = false, isDocumentPinned = false }) => {
        try {
          $host
            .delete(`/api/upload${isDocumentPinned ? `/document/${id}/${isMarkDelete}` : `/${id}`}`)
            .then((response) => {
              if (response.status === 200) {
                onSuccessDeleteFile(response.data);
              }
            })
            .catch((err) => {
              onError(err?.response?.data?.message);
            });
        } catch (error) {
          console.log(error);
          onError(error);
        }
      },
    };
  }

  wrapperSyncImage({ onError, onSuccess }) {
    return {
      /**
       *
       * @param {*} data
       * @param {*} date
       * @param {*} id
       * @param {{isDocumentPinned?: boolean, promiseType?: ("all"|"allSettled")}} param3
       * @returns {Promise}
       */
      uploadFileFunction: async (data, date, id, { isDocumentPinned = false, promiseType = "all" }) => {
        try {
          const dateFormat = dayjs(date).format("YYYY-MM-DD");
          const [year, month, day] = dateFormat.split("-");
          const request = [];

          Object.values(data).forEach((files) => {
            files.forEach((el) => {
              if (el !== null && el?.data !== null) {
                const formData = new FormData();

                formData.append("file", el?.data);
                formData.append("type", el?.type);
                request.push(
                  $host.post(
                    `/api/upload${isDocumentPinned ? "/document" : ""}/${year}/${month}/${day}/${id}`,
                    formData
                  )
                );
              }
            });
          });

          return Promise[promiseType](request)
            .then((response) => response)
            .catch((err) => {
              onError(err?.response?.data?.message);
            });
        } catch (error) {
          console.log(error);
        }
      },
    };
  }

  wrapperDocument({ onSuccess, onError }) {
    return {
      getAllDocuments: (id) => {
        $host
          .get(`/api/upload/document_owner/${id}`)
          .then((response) => {
            onSuccess(response.data);
          })
          .catch((error) => {
            onError(error?.response?.data?.message);
          });
      },
    };
  }
}

export default new UploadService();
