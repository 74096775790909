import { Comment } from "@ant-design/compatible";
import { List, Modal, Form, Button, notification, Space, Row, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../..";
import noteService from "../../services/noteService";
import { requestWrapper } from "../../utils/requestWrapper";

const NoteModal = ({ active, setActive, timetable, isCalendar = false }) => {
  const [notes, setNotes] = useState([]);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [idEditNote, setIdEditNote] = useState(null);
  const { user } = useContext(Context);

  const [getNoteEvent] = requestWrapper({
    requestFunction: noteService.getNoteEvent,
    onSuccess: (data) => {
      setNotes(
        data.map((el) => ({
          author: `${el.f} ${el.i} ${el.o}`,
          content: <p>{el.message}</p>,
          datetime: dayjs(el.createdAt).format("lll"),
          note_id: el.id,
          source: el.source,
        }))
      );
    },
    onError: () => {},
  });

  const [createNote] = requestWrapper({
    requestFunction: noteService.createNote,
    onSuccess: (data) => {
      getNoteEvent(data.note.timetableId);
      notification.success({ message: data.message });
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const [deleteNote] = requestWrapper({
    requestFunction: noteService.deleteNote,
    onSuccess: (data) => {
      getNoteEvent(timetable.id);
      notification.success({ message: data.message });
    },
    onError: () => {},
  });

  const btnCancel = () => {
    setIsEditNote(false);
    setValue("");
  };

  const [editNote] = requestWrapper({
    requestFunction: noteService.editNote,
    onSuccess: (data) => {
      btnCancel();
      setLoading(false);
      getNoteEvent(timetable.id);
      notification.success({ message: data.message });
    },
    onError: () => {
      setLoading(false);
    },
  });

  const onEditNote = () => {
    setLoading(true);
    editNote({
      id: idEditNote,
      message: value,
    });
  };

  const onSubmit = () => {
    setLoading(true);
    createNote({
      userId: user.user.id,
      timetableId: timetable.id,
      message: value,
      type: "eventNote",
      source: isCalendar ? "calendar" : "report",
    });

    setValue("");
  };

  useEffect(() => {
    if (active && timetable?.id) {
      getNoteEvent(timetable.id);
    }

    return () => {
      setNotes([]);
    };
  }, [active]);

  return (
    <Modal
      zIndex={999999}
      title={"Чат"}
      open={active}
      onCancel={() => {
        setActive(false);
      }}
      footer={null}
    >
      {notes.length ? (
        <List
          dataSource={notes}
          itemLayout="horizontal"
          renderItem={(props) => (
            <Comment
              {...props}
              actions={[
                <Row justify={"space-between"} key={"modal-note-btns"}>
                  <Button
                    key={props.note_id}
                    type="default"
                    onClick={() => {
                      setIsEditNote(true);
                      setIdEditNote(props.note_id);
                      setValue(props.content.props.children);
                    }}
                  >
                    <span className="comment-action">Изменить сообщение</span>
                  </Button>
                  <Button danger onClick={() => !isEditNote && deleteNote(props.note_id)} key={"modal-note-btn-delete"}>
                    <span className="comment-action">Удалить сообщение</span>
                  </Button>
                </Row>,
              ]}
              content={
                <div>
                  {props.content}
                  <div style={{ marginTop: "8px" }}>
                    <Tag color={props.source === "calendar" ? "blue" : props.source === "report" ? "green" : "warning"}>
                      {props.source === "calendar"
                        ? "Сообщение из календаря"
                        : props.source === "report"
                          ? "Сообщение из отчета"
                          : "Источник не определен"}
                    </Tag>
                  </div>
                </div>
              }
            />
          )}
        />
      ) : (
        <></>
      )}
      <Form.Item>
        <TextArea
          rows={3}
          placeholder={"Текст сообщения..."}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      </Form.Item>
      <Form.Item>
        {!isEditNote ? (
          <Button htmlType="submit" loading={loading} onClick={onSubmit} type="primary">
            Добавить сообщение
          </Button>
        ) : (
          <Space>
            <Button htmlType="submit" loading={loading} onClick={onEditNote} type="primary">
              Подтвердить изменения
            </Button>
            <Button onClick={() => btnCancel()}>Отменить изменения</Button>
          </Space>
        )}
      </Form.Item>
    </Modal>
  );
};

export default NoteModal;
